<template lang="pug">
Layout(@back="onBack" v-if="isSelectScreen")
  .new-campaign-flow-optimize
    OmHeading.text-center(h2) {{ $t('newCampaignFlow.websiteOptimization.title') }}
    .new-campaign-flow-optimize-block
      OmHeading(h3) {{ $t('newCampaignFlow.websiteOptimization.optimize.title') }}
      OmSelectCards(:options="optimizeOptions" v-model="about")
        .circle-item-background(slot="backgroundContent")
    .new-campaign-flow-optimize-block
      OmHeading(h3) {{ $t('newCampaignFlow.websiteOptimization.personalize.title') }}
      OmSelectCards(:options="personalizeOptions" v-model="about")
        .circle-item-background(slot="backgroundContent")
    MoreDetails(
      :aiTypes="aiTypes"
      :about="about"
      @track="onTrack($event)"
      :type="getOptimizationType(about)"
      @navigate="onNavigate"
      @deselect="onDeselect"
    )
    Book(
      :about="about"
      @track="onTrack($event)"
      :type="getOptimizationType(about)"
      @close="openDetailsModal"
    )
    DcPersonalization(@close="onDeselect")
    NewCampaign(@close="openDetailsModal")
    LastRequestDateOld
router-view(v-else)
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import commonFlowsMixin, { OPTIMIZATION_TYPES } from '@/mixins/commonFlows';
  import { SAB_NEW_CHIP } from '@/utils/features';

  const ICON_BY_TYPE = {
    abTest: 'ob-ab-tests',
    sab: 'auto-ab-tests',
    sppo: 'window-shopping',
    personalizer: 'ob-personalization',
    smartPersonalizer: 'auto-personalization',
    multiCampaignABTest: 'multi-campaign-ab-test',
  };

  export default {
    components: {
      Layout: () => import('@/components/NewCampaignFlow/Layout.vue'),
    },
    mixins: [commonFlowsMixin],
    computed: {
      ...mapGetters(['hasAccountFeature']),
      optimizeOptions() {
        return this.mapOptions(
          'optimize',
          OPTIMIZATION_TYPES.filter((type) => type !== 'smartPopup'),
        );
      },
      personalizeOptions() {
        return this.mapOptions('personalize', ['personalizer', 'smartPersonalizer']);
      },
      isSelectScreen() {
        return this.$route.name === 'new_campaign_flow_optimize';
      },
      chipByType() {
        const chips = {
          sppo: { color: 'gradient', text: 'newCampaignFlow.websiteOptimization.upgrade' },
          smartPersonalizer: {
            color: 'gradient',
            text: 'newCampaignFlow.websiteOptimization.upgrade',
          },
        };

        if (this.hasAccountFeature(SAB_NEW_CHIP)) {
          chips.sab = {
            color: 'forest',
            text: 'new',
            upperCase: true,
          };
        }

        return chips;
      },
    },
    watch: {
      about(v) {
        if (v) {
          this.onTrack({ name: 'select', properties: { value: v } });
          this.openDetailsModal();
        }
      },
    },
    mounted() {
      window.__skip_wizard_recommendation_loader = true;
      this.fetchAccount();
    },
    methods: {
      ...mapActions(['fetchAccount']),
      mapOptions(type, keys) {
        return keys.map((value) => ({
          icon: ICON_BY_TYPE[value],
          key: this.$t(`newCampaignFlow.websiteOptimization.${type}.${value}.title`),
          value,
          details: this.$t(`newCampaignFlow.websiteOptimization.${type}.${value}.description`),
          arrow: false,
          chip: this.chipByType[value],
        }));
      },
      setQueryStringParameter(name, value) {
        const params = new URLSearchParams(window.location.search);
        params.set(name, value);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
      },
      onNavigate(page) {
        this.setQueryStringParameter('flow', this.about);
        this.$emit('select', page);
      },
      onBack() {
        this.$router.go(-1);
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .new-campaign-flow
    &-optimize
      padding-bottom: 4rem
      &-block
        margin-top: 3.75rem
        .heading
          margin-bottom: 1.25rem
      .circle-item-background
        width: 4.5rem
        height: 4.5rem
        border-radius: 100%
        background: $om-peach-200
</style>
